<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col sm="12" md="12" lg="12" xl="12" cols="12">
				<v-card tile flat min-height="250" :loading="UserLoading" class="mgn_bg rounded-xl d-flex justify-start align-start overflow-hidden ma-0">
					<particles oncontextmenu="return false;"/>

					<v-col sm="12" md="12" lg="12" xl="10" cols="12" class="mgn_header rounded-0 rounded-br-xl pa-5">
						<v-row align="center" justify="start">
							<v-col sm="3" md="1" lg="1" xl="1" cols="12" class="pa-0 justify-end align-end">
								<v-img class="profile_pic" max-width="130" :src="$store.state.user.profilePic" transition="fade-transition"></v-img>
							</v-col>
							
							<v-col sm="9" md="10" lg="11" xl="11" cols="12" class="pa-0">
								<v-container>
									<v-col v-if="$store.state.user.aggregated_points_status" align="start" cols="12" class="px-0">
										<h2 class="ma-0">Hi, {{ $store.state.user.nickname }}</h2>
										<h3>Your {{ $store.state.user.aggregated_points[0].shorthand }} points: {{ $store.state.user.aggregated_points[0].points }}</h3>
									</v-col>

									<v-row v-else class="mx-1" justify="start" align="center">
										<v-col cols="12" class="px-0">
											<h2 class="ma-0">Hi, {{ $store.state.user.nickname }}</h2>
											<h3 class="mb-3">Your points:</h3>
											<v-row justify="start" align="center">
												<v-chip outlined label class="mgn_chip justify-center ma-1" v-for="(user, index) in UserPoints" :key="index">
													{{ user.points }} {{ user.shorthand }}
												</v-chip>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
							</v-col>
						</v-row>
					</v-col>
				</v-card>
			</v-col>

			<v-col cols="12" class="mb-0 pb-0">
				<v-row>
					<v-col sm="12" md="2" lg="2" xl="2" cols="12">
						<h1>Rewards</h1>
					</v-col>

					<v-spacer></v-spacer>

					<v-col sm="12" md="2" lg="2" xl="2" cols="12"> 
						<v-select
							v-model="v_model_enrolled"
							:items="enrolled"
							@change="filterRewards(v_model_enrolled)"
							item-text="nickname"
							label="Streamer"
							flat
							filled
							return-object
						></v-select>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" v-if="rewardsLoading">
				<v-row>
					<v-col sm="12" md="5" lg="2" xl="2" cols="12" v-for="(item, index) in placeholder" :key="index">
						<v-skeleton-loader class="mx-auto" type="article, actions"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>
			
			<v-col cols="12" v-else-if="!rewardsLoading" v-for="(reward, index) in rewardsCategorized" :key="index">
				<h2>{{ reward.category }}</h2>

				<v-row>
					<v-col sm="6" md="4" lg="3" xl="2" cols="12" v-for="reward in reward.rewards" :key="reward.id">
						<v-tooltip
							bottom 
							color="primary"
							:disabled="!tooltipMessageEnabler(reward)"
						>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<RewardItem :reward="reward"/>
								</div>
							</template>

							<span v-if="tooltipMessageEnabler(reward)">{{ disableMsg }}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" v-if="!rewardsLoading && rewards.length === 0">
				<p class="ma-0">There are no rewards currently to redeem.</p>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
import particles from "../assets/particles.vue";
import RewardItem from './RewardItem.vue';

export default {
	data() {
		return {
			placeholder: 6,
			enrolled: [],
			v_model_enrolled: {},
			categories: [],
			rewards: [],
			rewardsCategorized: [],
			stats: [],
			rewardsLoading: true,
			userLoading: true,
			statsLoading: true,
			disableMsg: '',
		}
	},

	components: {
		RewardItem,
		particles
	},

	computed: {
		UserPoints() {
			return this.$store.state.user.points;
		},

		UserLoading() {
			return this.$store.state.userLoading;
		}
	},

	async mounted() {
		let a = this.getParameterByName('openid.claimed_id');
		let arr = a.split('/');
		let id = arr[arr.length - 1];
		if (id) this.HandleSteamID(id);

		await this.$store.dispatch('fetchUserInfo');
		await this.fetchRewards();
		await this.fetchStreamers();
	},

	methods: {
		tooltipMessageEnabler (reward) {
			if (!reward.repeatable && this.UserClaimedReward(reward.id)) {
				this.disableMsg = 'You already redeemed this reward!'
				return true;
			}

			if (reward.has_max_redeems && reward.max_redeems >= reward.redeemers.length) {
				this.disableMsg = 'Reward is disabled as it reached maximum number of redeems.'
				return true;
			}

			if (reward.steam_auth_required && this.$store.state.user.steam_id === null) {
				this.disableMsg = 'You need to connect your Steam account to redeem this!'
				return true;
			}
			
			return false;
		},

		UserClaimedReward(reward_id){
			return this.$store.state.user.redeems.some(redeem => redeem.id === reward_id);
		},

		getParameterByName(name) {
			name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
			var regexS = "[\\?&]" + name + "=([^&#]*)";
			var regex = new RegExp(regexS);
			var results = regex.exec(window.location.href);
			if (results == null) return "";
			else return decodeURIComponent(results[1].replace(/\+/g, " "));
		},

		filterRewards(val) {
			this.rewardsCategorized.forEach(cat => {
				if (val.id === '0') {
					cat.rewards = this.rewards.filter(reward => reward.category.category === cat.category);
					return;
				}

				cat.rewards = this.rewards.filter(reward => reward.points_requirements.some(points => points.id === val.id && points.points > 0) && reward.category.category === cat.category)
			});
		},

		async fetchRewards() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/user/rewards`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.rewards) {
						this.rewards = res.rewards;

						let categ = this.rewards.map(reward => reward.category.category);
						this.categories = [...new Set(categ)];

						this.categories.forEach((cat, index) => {
							this.rewardsCategorized.push({
								category: cat,
								rewards: []
							});

							this.rewards.forEach(reward => {
								if (cat === reward.category.category) {
									this.rewardsCategorized[index].rewards.push(reward);
								}
							});
						});

						return;
					}
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.rewardsLoading = false;
			}
		},

		async fetchStreamers() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/enrolled/streamers`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.streamers) {
						this.enrolled = res.streamers;
						this.enrolled.push({
							id: '0',
							nickname: 'All'
						});

						this.v_model_enrolled = this.enrolled.find(enrolled => enrolled.id === '0')
					}
				}
				
				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.rewardsLoading = false;
			}
		},

		async HandleSteamID(id) {
			try {
				const request = await fetch(`${this.$store.state.base_url}/steam/id`, {
					method: 'POST',
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					},
					body: JSON.stringify({
						id: id
					})
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.steamIdAuth) {
						this.$router.push('/dashboard');
						await this.$store.dispatch('fetchUserInfo');
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
		}
	},
}
</script>

<style>
.mgn_bg {
	background-image: url('/imgs/banner.png');
	background-position: center;
	background-size: cover;
	background-blend-mode: overlay;
}

.v-card--link:before {
  background: none;
}

.rewardsCategorizedard {
	min-height: 350px;
	max-height: 500px;
	align-content: space-between;
}
</style>